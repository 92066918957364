import { Box } from "@mui/material";
import { AvailableLanguages, IModifiedValuesProps } from "@smartrr/shared/entities/CustomerPortalTheme";
import { IShopifyGetThemesResponse, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import React, { useCallback, useEffect, useState } from "react";

import { useSmartrrVendorSelector, useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";

import { TranslationsContainer } from "./components/TranslationsContainer";
import { useToast } from "../../_sharedComponents/Toast/ToastProvider";
import { Spinner } from "../components/elements/Spinner";

import { loadCustomerPortalTheme } from "@vendor-app/app/_state/actionCreators/customerPortalTheme";
import { LANGUAGE_SELECTION_OPTIONS } from "./constants";

export const AdminTranslationsRoute = () => {
  const restClient = useRestClient();
  const dispatch = useSmartrrVendorDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { addToast } = useToast();
  const customerPortalTranslationOverrides =
    useSmartrrVendorSelector(state => state.customerPortalTheme.customerPortalTheme.translationOverrides) ??
    defaultCustomerPortalThemeConfig.translationOverrides;
  const [activeLanguage, setActiveLanguage] = useState<AvailableLanguages>(
    customerPortalTranslationOverrides?.activeLanguage
  );
  const [modifiedValues, setModifiedValues] = useState<Record<string, IModifiedValuesProps>>({});
  const [shopifyThemes, setShopifyThemes] = useState<IShopifyGetThemesResponse["themes"]>([]);
  const [mainThemeId, setMainThemeId] = useState<number>();

  const getThemes = useCallback(async () => {
    const res = await getShopifyThemes(restClient);
    setIsLoading(true);
    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
      setIsLoading(false);

      const mainTheme = res.body.themes.find(t => t.role === "main")!;
      if (mainTheme) {
        setMainThemeId(mainTheme.id);
      } else {
        addToast("Error getting main theme id");
      }
    }

    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
    }
  }, []);

  const initializeData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([dispatch(loadCustomerPortalTheme()), getThemes()]);
    setIsLoading(false);
  }, [dispatch, getThemes]);

  useEffect(() => {
    void initializeData();
  }, []);

  useEffect(() => {
    if (customerPortalTranslationOverrides) {
      setModifiedValues(customerPortalTranslationOverrides[activeLanguage]);
    }
  }, [customerPortalTranslationOverrides, activeLanguage]);

  useEffect(() => {
    setActiveLanguage(customerPortalTranslationOverrides.activeLanguage);
  }, [customerPortalTranslationOverrides]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "10px",
        ".Polaris-Page": {
          width: "100%",
          padding: "0px",
        },
      }}
    >
      <TranslationsContainer
        customerPortalTranslationOverrides={customerPortalTranslationOverrides! ?? {}}
        activeLanguage={activeLanguage}
        setActiveLanguage={setActiveLanguage}
        modifiedValues={modifiedValues}
        setModifiedValues={setModifiedValues}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        themes={shopifyThemes}
        mainThemeId={mainThemeId!}
        languageSelectionOption={LANGUAGE_SELECTION_OPTIONS}
      />
    </Box>
  );
};
