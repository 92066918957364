import { AvailableLanguages } from "@smartrr/shared/entities/CustomerPortalTheme";
export const LANGUAGE_SELECTION_OPTIONS = [
  {
    label: "English",
    value: AvailableLanguages.ENGLISH,
  },
  {
    label: "French",
    value: AvailableLanguages.FRENCH,
  },
  {
    label: "Spanish",
    value: AvailableLanguages.SPANISH,
  },
  {
    label: "Portuguese (Portugal)",
    value: AvailableLanguages.PORTUGUESE,
  },
];
